#header {
  background-color: #fffcf7;
  padding: 41px 0 46px 0;
  position: relative;
  box-shadow: 0px 4px 12px 0px rgb(234, 240, 246);
}

#header nav ul {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  gap: 60px;
  list-style: none;
}

#header nav {
  position: relative;
}

.logo {
  width: 152.7px;
}

.nav-item a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #000000;
  text-decoration: none;
}

.nav-subscribe-btn {
  background: #e27c23;
  border-radius: 7px;
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
  border: 1px solid #e27c23;
  padding: 13px 47px 13px 39px;
  margin-left: 6px;
  transition: all ease 0.5s;
}

#header .blue-circle {
  width: 13px;
  height: 13px;
  background-color: #65c6f6;
  border-radius: 100%;
  position: absolute;
  right: 315px;
  top: -10px;
}

.nav-subscribe-btn:hover {
  background-color: white;
  color: #e27c23;
}

#mobile-nav ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 10px;
}

#mobile-nav-toggler {
  border: none;
  background-color: transparent;
}

.offcanvas {
  width: 60% !important;
}

@media (max-width: 1024px) {
  #header {
    padding: 20px 0 20px 0;
  }

  .logo {
    width: 100.7px;
  }

  .nav-subscribe-btn {
    font-size: 20px;
    line-height: 24px;
    padding: 8px 20px 8px 20px;
    margin-left: 0;
  }

  #header nav ul {
    gap: 30px;
  }

  .nav-item a {
    font-size: 18px;
    line-height: 24px;
  }

  #header .blue-circle {
    right: 205px;
  }
}

@media (max-width: 991px) {
  #header {
    padding: 20px 10px;
  }
  #header nav ul {
    gap: 20px;
  }
  #mobile-nav ul {
    gap: 25px;
  }
  .nav-item a {
    font-size: 17px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 70.7px;
  }
}
